import { Box, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import { useEffect } from 'react';
import { CareNavService } from '../../../shared/service/services_v2';


function ExternalPlatoLanding() {
  const { search } = useLocation();
  const history = useHistory();


  useEffect(() => {
    const token = new URLSearchParams(search).get('token');
    if (token) {
      getData(token);
    }
  }, [search]);

  const getData = async (token: string) => {
    const response = await CareNavService.getPlatoData(token);
    if (response.data && response.data.data.email) {
      history.replace('/users?email=' + response.data.data.email);
    } else {
      history.replace('/users');
    }
  };


  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection={'column'}
      gap={4}
    >
      <Typography variant='h5'>
        Redirecting...
      </Typography>
    </Box>
  );
}

export default ExternalPlatoLanding;
