import {
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { FormikProps } from 'formik';
import {
  DefaultCountryStatuses,
  OffboardingStrategies,
  TeamStatuses,
} from '../../../../../../shared/constants/Organisation';
import LabelWithAsterik from '../../../../../components/LabelWithAsterik/LabelWithAsterik';
import SlidingSection from '../../../../../components/slidingSection/SlidingSection';
import { IAddClientInputs } from './AddOrganisationDialogBase';

const OrgDetailsStep = ({
  formik,
}: {
  formik: FormikProps<IAddClientInputs>;
}) => {
  return (
    <SlidingSection>
      <Grid container spacing={2}>
        <Grid item xs={6} width={6}>
          <InputLabel id="organisation-name">
            <LabelWithAsterik title="Organisation name" />
          </InputLabel>
          <TextField
            onChange={formik?.handleChange}
            autoFocus
            fullWidth
            id="organisation-name"
            value={formik?.values?.name ?? ''}
            error={formik?.touched?.name && Boolean(formik?.errors?.name)}
            helperText={formik?.touched?.name && formik?.errors?.name}
            name="name"
            placeholder="Add name"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel id="email-address">
            <LabelWithAsterik title="Email address" />
          </InputLabel>
          <TextField
            onChange={formik?.handleChange}
            fullWidth
            id="email-address"
            name="email"
            value={formik?.values?.email ?? ''}
            error={formik?.touched?.email && Boolean(formik?.errors?.email)}
            helperText={formik?.touched?.email && formik?.errors?.email}
            placeholder="Add Email Address"
            size="small"
          />
        </Grid>
        <Grid item xs={6} width={6}>
          <InputLabel id="contact-number">
            <LabelWithAsterik title="Contact number" />
          </InputLabel>
          <TextField
            onChange={formik?.handleChange}
            fullWidth
            id="contact-number"
            name="contactNumber"
            value={formik?.values?.contactNumber ?? ''}
            error={
              formik?.touched?.contactNumber &&
              Boolean(formik?.errors?.contactNumber)
            }
            helperText={
              formik?.touched?.contactNumber && formik?.errors?.contactNumber
            }
            placeholder="Add contact number"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor="default-team-status">
            <LabelWithAsterik title="Department Selection" />
          </InputLabel>
          <Select
            onChange={formik?.handleChange}
            fullWidth
            id="default-team-status"
            name="defaultTeamStatus"
            value={formik?.values?.defaultTeamStatus ?? ''}
            error={
              formik?.touched?.defaultTeamStatus &&
              Boolean(formik?.errors?.defaultTeamStatus)
            }
            placeholder="Add default team status"
            size="small"
          >
            {TeamStatuses.map((status) => (
              <MenuItem key={status.value} value={status.value}>
                {status.label}
              </MenuItem>
            ))}
          </Select>
          {formik?.touched?.defaultTeamStatus &&
          Boolean(formik?.errors?.defaultTeamStatus) ? (
            <FormHelperText sx={{ color: 'error.main' }}>
              {formik?.touched?.defaultTeamStatus &&
                formik?.errors?.defaultTeamStatus}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor="default-team-status">
            Offboarding strategy
          </InputLabel>
          <Select
            onChange={(event: SelectChangeEvent<number>) =>
              formik.setFieldValue(
                'offBoardingStrategy',
                Number(event.target.value),
              )
            }
            fullWidth
            id="offboarding-strategy"
            name="offBoardingStrategy"
            value={formik?.values?.offBoardingStrategy ?? ''}
            error={
              formik?.touched?.offBoardingStrategy &&
              Boolean(formik?.errors?.offBoardingStrategy)
            }
            placeholder="Add offboarding strategy"
            size="small"
          >
            {OffboardingStrategies.map((strategy) => (
              <MenuItem key={strategy.value} value={strategy.value}>
                {strategy.label}
              </MenuItem>
            ))}
          </Select>
          {formik?.touched?.offBoardingStrategy &&
          Boolean(formik?.errors?.offBoardingStrategy) ? (
            <FormHelperText sx={{ color: 'error.main' }}>
              {formik?.touched?.offBoardingStrategy &&
                formik?.errors?.offBoardingStrategy}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor="default-country-status">
            Default country status
          </InputLabel>
          <Select
            onChange={(event: SelectChangeEvent<number>) =>
              formik.setFieldValue(
                'defaultCountryStatus',
                Number(event.target.value),
              )
            }
            fullWidth
            id="default-country-status"
            name="defaultCountryStatus"
            value={formik?.values?.defaultCountryStatus ?? ''}
            error={
              formik?.touched?.defaultCountryStatus &&
              Boolean(formik?.errors?.defaultCountryStatus)
            }
            placeholder="Add default country status"
            size="small"
          >
            {Object.entries(DefaultCountryStatuses).map(([key, value]) => (
              <MenuItem key={`countrystatus-${key}-${value}`} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
          {formik?.touched?.defaultCountryStatus &&
          Boolean(formik?.errors?.defaultCountryStatus) ? (
            <FormHelperText sx={{ color: 'error.main' }}>
              {formik?.touched?.defaultCountryStatus &&
                formik?.errors?.defaultCountryStatus}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={12} width={12}>
          <InputLabel id="domains">
            <LabelWithAsterik title="Add domains, separated by commas" />
          </InputLabel>
          <TextField
            // onChange={(event) =>
            //   setOrgData({
            //     ...orgData,
            //     domains: event.target.value.split(','),
            //   })
            // } // TODO add this spliting while calling API
            onChange={formik?.handleChange}
            fullWidth
            id="domains"
            name="domains"
            value={formik?.values?.domains ?? ''}
            error={formik?.touched?.domains && Boolean(formik?.errors?.domains)}
            helperText={formik?.touched?.domains && formik?.errors?.domains}
            placeholder="Add domains(comma separated)"
            size="small"
          />
        </Grid>
      </Grid>
    </SlidingSection>
  );
};

export default OrgDetailsStep;
