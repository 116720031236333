import { ApiConfigV2 } from '../../apiConfigV2';
import {
  HelplineSessionCreationSource,
  HelplineSessionType,
} from '../../constants/Helpline';
import { CareNavClientInfoV2 } from '../../types/helpline/CareNavForm';
import { NetworkInstanceV2 } from './NetworkInstance';

export class CareNav {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getUserDetails(payload: { email: string; organisation: number | null }) {
    return this.client.post(ApiConfigV2.api.careNav.getUserDetails, payload);
  }

  getUserProviderDetails(userId: string) {
    return this.client.get(
      ApiConfigV2.api.careNav.getUserProviderDetails.replace(
        '{{userId}}',
        userId?.toString(),
      ),
    );
  }

  createSession(payload: {
    clientId: string;
    startTime: string;
    sessionType: HelplineSessionType;
    source: HelplineSessionCreationSource;
  }) {
    return this.client.post(ApiConfigV2.api.careNav.createSession, {
      ...payload,
      sessionType: payload?.sessionType?.toString(),
    });
  }

  updateSession(sessionId: string, payload: Record<string, any>) {
    return this.client.patch(
      ApiConfigV2.api.careNav.updateSession.replace('{{sessionId}}', sessionId),
      payload,
    );
  }

  createClient(payload: Record<string, any>) {
    return this.client.post(ApiConfigV2.api.careNav.createClient, payload);
  }

  getOrganisationsList() {
    return this.client.get(ApiConfigV2.api.careNav.getOrganisationsList);
  }

  createNote(sessionId: string, payload: FormData) {
    return this.client.post(
      ApiConfigV2.api.careNav.createNote.replace('{{sessionId}}', sessionId),
      payload,
    );
  }

  getPlatoData(platoToken: string) {
    return this.client.post(ApiConfigV2.api.careNav.getPlatoData, {
      platoToken,
    });
  }
}
