import * as Yup from 'yup';

const PhoneRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const addClientFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .typeError('Name is invalid')
    .required('Name is required')
    .trim(),
  email: Yup.string()
    .email()
    .typeError('Email is invalid')
    .required('Email is required')
    .trim(),
  contactNumber: Yup.string()
    .typeError('Contact number is invalid')
    .required('Contact number is required')
    // .matches(PhoneRegex, 'Contact number is invalid') Note: will add it later, added it bcz have seen people entering N/A or some random string here
    .trim(),
  domains: Yup.string()
    .typeError('Domain is invalid')
    .required('Domain is required')
    .trim(),
  offBoardingStrategy: Yup.number()
    .typeError('Offboarding strategy is invalid')
    .optional(),
  defaultCountryStatus: Yup.number()
    .typeError('Default country status is invalid')
    .optional(),
  defaultTeamStatus: Yup.string()
    .typeError('Department selection is invalid')
    .required('Department selection is required')
    .trim(),
  status: Yup.string()
    .typeError('Status is invalid')
    .required('Status is required')
    .trim(),
  totalHeadCount: Yup.number()
    .typeError('Total head count is invalid')
    .test(
      'requiredIfStatusIs1',
      'Total head count is required',
      function (value) {
        const { status } = this.parent;
        if (status === '1') {
          return value !== undefined;
        }
        return true;
      },
    ),
  contractValue: Yup.string().typeError('Contract value is invalid').trim(),
  contractStartDate: Yup.string()
    .typeError('Contract start date is invalid')
    .trim(),
});
