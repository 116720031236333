import { isProduction } from '../../utilities/App';

export const DefaultCountryStatuses = {
  1: 'Hiding country',
  2: 'Showing country',
};

export const OrganisationStatuses = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
] as const;

// Note: Not Adding ALL status here bcz it is just to indicate ALL tabs for listing, it is not stored in the DB
export enum OrganisationStatusEnum {
  Active = 1,
  Closed = 2,
  Trial = 3,
  OnBoarding = 4,
  Pilot = 5,
  Outplacement = 6,
}

export const OrganisationStatusInList = {
  '0': {
    title: 'All',
    color: 'var(--chip-grey-color)',
    bgColor: 'var(--chip-grey-bg-color)',
    order: 0,
  },
  '1': {
    title: 'Active',
    color: 'var(--chip-green-color)',
    bgColor: 'var(--chip-green-bg-color)',
    order: 1,
  },
  '4': {
    title: 'Onboarding',
    color: 'var(--chip-green2-color)',
    bgColor: 'var(--chip-green2-bg-color)',
    order: 2,
  },
  '3': {
    title: 'Trial',
    color: 'var(--chip-yellow-color)',
    bgColor: 'var(--chip-yellow-bg-color)',
    order: 3,
  },
  '5': {
    title: 'Pilot',
    color: 'var(--chip-blue-color)',
    bgColor: 'var(--chip-blue-bg-color)',
    order: 4,
  },
  '2': {
    title: 'Closed',
    color: 'var(--chip-red-color)',
    bgColor: 'var(--chip-red-bg-color)',
    order: 5,
  },
  '6': {
    title: 'Outplacement',
    color: 'var(--chip-purple-color)',
    bgColor: 'var(--chip-purple-bg-color)',
    order: 6,
  },
} as Record<
  (typeof OrganisationStatuses)[number],
  { title: string; color: string; bgColor: string; order: number }
>;

export const BillingInclusions = [
  'coach',
  'coach_unlimited',
  'clinical',
  'dependent',
];

export enum BillingPlanType {
  Organisation = 'org',
  Individual = 'inv',
  Dependent = 'dep',
}

export enum BillingPlanTier {
  Premium = 'prm',
  Credits = 'cred',
}

export enum OrgDefaultTeamStatuses {
  NOT_SHOWING = '1',
  SHOWING_SELECTION = '4',
}

export const TeamStatuses = [
  {
    value: OrgDefaultTeamStatuses.NOT_SHOWING,
    label: 'Not Showing',
  },
  {
    value: OrgDefaultTeamStatuses.SHOWING_SELECTION,
    label: 'Showing Selection',
  },
];

export const OffboardingStrategies: { value: number; label: string }[] = [
  {
    value: 0,
    label: 'No Action, Remove access',
  },
  {
    value: 1,
    label: 'Mark as B2C',
  },
  {
    value: 2,
    label: 'Delete user data',
  },
];

export enum TeamMemberRoleEnum {
  MEMBER = '0',
  MANAGER = '1',
  HR = '2',
}

export const OrganisationTeamMemberRole: {
  [id: string]: { label: string; color: string };
} = {
  [TeamMemberRoleEnum.MEMBER]: {
    label: 'MEMBER',
    color: 'black',
  },
  [TeamMemberRoleEnum.MANAGER]: {
    label: 'MANAGER',
    color: 'var(--chip-red-color)',
  },
  [TeamMemberRoleEnum.HR]: {
    label: 'HR',
    color: 'var(--chip-red-color)',
  },
};

export const OrganisationTeamMemberRoleDropdownValues = [
  { value: TeamMemberRoleEnum.MEMBER, label: 'MEMBER' },
  { value: TeamMemberRoleEnum.MANAGER, label: 'MANAGER' },
  { value: TeamMemberRoleEnum.HR, label: 'HR' },
];

export enum TeamMemberStatusEnum {
  INVITED = '0',
  ACTIVE = '1',
  DISABLED = '2',
  NEW = '3',
  PENDING = '4',
  MIGRATED = '5',
  DELETED = '6',
}

export const TeamMemberStatusRevised: {
  [id: string]: {
    label: string;
    bgColor: string;
    color: string;
    editable: boolean;
  };
} = {
  [TeamMemberStatusEnum.INVITED]: {
    label: 'Invited',
    bgColor: 'var(--chip-yellow-bg-color)',
    color: 'var(--chip-yellow-color)',
    editable: true,
  },
  [TeamMemberStatusEnum.ACTIVE]: {
    label: 'Active',
    bgColor: 'var(--chip-green-bg-color)',
    color: 'var(--chip-green-color)',
    editable: false,
  },
  [TeamMemberStatusEnum.DISABLED]: {
    label: 'Disabled',
    bgColor: 'var(--chip-grey-bg-color)',
    color: 'var(--chip-grey-color)',
    editable: false,
  },
  [TeamMemberStatusEnum.NEW]: {
    label: 'New',
    bgColor: 'var(--chip-blue-bg-color)',
    color: 'var(--chip-blue-color)',
    editable: true,
  },
  [TeamMemberStatusEnum.PENDING]: {
    label: 'Pending',
    bgColor: 'var(--chip-yellow-bg-color)',
    color: 'var(--chip-yellow-color)',
    editable: false,
  },
  [TeamMemberStatusEnum.MIGRATED]: {
    label: 'Migrated',
    bgColor: 'var(--chip-purple-bg-color)',
    color: 'var(--chip-purple-color)',
    editable: false,
  },
  [TeamMemberStatusEnum.DELETED]: {
    label: 'Deleted',
    bgColor: 'var(--chip-red-bg-color)',
    color: 'var(--chip-red-color)',
    editable: false,
  },
};

export const TeamMemberStatusDropdownValues = [
  { value: TeamMemberStatusEnum.INVITED, label: 'Invited' },
  { value: TeamMemberStatusEnum.ACTIVE, label: 'Active' },
  { value: TeamMemberStatusEnum.DISABLED, label: 'Disabled' },
  { value: TeamMemberStatusEnum.NEW, label: 'New' },
  { value: TeamMemberStatusEnum.PENDING, label: 'Pending' },
  { value: TeamMemberStatusEnum.MIGRATED, label: 'Migrated' },
  { value: TeamMemberStatusEnum.DELETED, label: 'Deleted' },
];

export const OrganisationTeamMemberRoleRevised: {
  [id: string]: { label: string; color: string; bgColor: string };
} = {
  [TeamMemberRoleEnum.MEMBER]: {
    label: 'MEMBER',
    color: 'var(--chip-grey-color)',
    bgColor: 'var(--chip-grey-bg-color)',
  },
  [TeamMemberRoleEnum.MANAGER]: {
    label: 'MANAGER',
    color: 'var(--chip-red-color)',
    bgColor: 'var(--chip-red-bg-color)',
  },
  [TeamMemberRoleEnum.HR]: {
    label: 'HR',
    color: 'var(--chip-red-color)',
    bgColor: 'var(--chip-red-bg-color)',
  },
};

export const CreditPoolTypes = [
  { type: '0', label: 'Coaching (Virtual)' },
  { type: '1', label: 'Care (Virtual)' },
  { type: '2', label: 'Webinar' },
  { type: '5', label: 'Nutritionist' },
  { type: '6', label: 'Fitness' },
  { type: '7', label: 'Financial' },
  // { type: '8', label: 'Executive' },
  { type: '9', label: 'Legal' },
  { type: '10', label: 'Care (Offsite)' },
  { type: '11', label: 'Coaching (Offsite)' },
  { type: '12', label: 'Care (Onsite)' },
];

export const CreditPlanUse = [
  { type: 'emp', label: 'Employee' },
  { type: 'dep', label: 'Dependent' },
];

export const OrganisationConfigMap = {
  courseListingFilter: 'Restricted Courses',
  disableDefaultCourse: 'Personality test disabled',
  disablePeriodicSessions: 'Wellbeing checkin disabled',
  eapHotLine: 'EAP Hotline numbers',
  hideGoals: 'Skip user goals',
  hideJournals: 'Journals disabled',
  hidePsychologicalProfile: 'PsychologicalProfile hidden',
  hideRescueSessions: 'Rescue session hidden',
  isResearchUser: 'Research Project',
  signupConsentURL: 'Onboarding consent link',
  skipGender: 'Skip profile gender',
  sosTitle: 'SOS Button Title',
  sosUrl: 'SOS Button Link',
};

export const OrganisationStatusForCreation = {
  '1': 'Annual',
  '3': 'Trial',
  '5': 'Pilot',
} as Record<(typeof OrganisationStatuses)[number], string>;

interface MfaFactorNamesType {
  [key: number]: string;
}

export const MfaFactorNames: MfaFactorNamesType = {
  1: 'TOTP',
  2: 'Biometric',
};

export const TrialMaxDays = 30;
export const PilotMaxDays = 90;

export enum DependentUserStatus {
  invited = '0',
  accepted = '1',
  active = '2',
  inviteExpire = '3',
  declined = '4',
  pending = '5',
  approved = '6',
  rejected = '7',
  deleted = '8',
}

export const DependentUserStatusRevised: {
  [id: string]: {
    label: string;
    bgColor: string;
    color: string;
    editable: boolean;
  };
} = {
  [DependentUserStatus.invited]: {
    label: 'Invited',
    bgColor: 'var(--chip-yellow-bg-color)',
    color: 'var(--chip-yellow-color)',
    editable: true,
  },
  [DependentUserStatus.accepted]: {
    label: 'Accepted',
    bgColor: 'var(--chip-green-bg-color)',
    color: 'var(--chip-green-color)',
    editable: false,
  },
  [DependentUserStatus.active]: {
    label: 'Active',
    bgColor: 'var(--chip-green-bg-color)',
    color: 'var(--chip-green-color)',
    editable: false,
  },
  [DependentUserStatus.inviteExpire]: {
    label: 'Invite Expired',
    bgColor: 'var(--chip-grey-bg-color)',
    color: 'var(--chip-grey-color)',
    editable: false,
  },
  [DependentUserStatus.declined]: {
    label: 'Declined',
    bgColor: 'var(--chip-red-bg-color)',
    color: 'var(--chip-red-color)',
    editable: false,
  },
  [DependentUserStatus.pending]: {
    label: 'Pending',
    bgColor: 'var(--chip-yellow-bg-color)',
    color: 'var(--chip-yellow-color)',
    editable: true,
  },
  [DependentUserStatus.approved]: {
    label: 'Approved',
    bgColor: 'var(--chip-blue-bg-color)',
    color: 'var(--chip-blue-color)',
    editable: false,
  },
  [DependentUserStatus.rejected]: {
    label: 'Rejected',
    bgColor: 'var(--chip-red-bg-color)',
    color: 'var(--chip-red-color)',
    editable: false,
  },
  [DependentUserStatus.deleted]: {
    label: 'Deleted',
    bgColor: 'var(--chip-grey-bg-color)',
    color: 'var(--chip-grey-color)',
    editable: false,
  },
};
