import { Typography } from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { MaterialIcons } from '../../../../../assets/MaterialIcons';
import {
  AclActions,
  AclModules,
} from '../../../../../shared/constants/Ability';
import { OrganisationServiceV2 } from '../../../../../shared/service/services_v2';
import { AbilityContext } from '../../../../components/Acl/Can';
import SectionHeader from '../../../../components/SectionHeader/SectionHeader';
import TableViewComponent from '../../../../components/TableViewComponent/TableViewComponent';
import StyledButton from '../../../../widgets/styledButton/StyledButton';
import { OrgMappedProviderColumns } from '../../utils/grid-columns/OrganisationProviderGridColumns';
import AddOrgProviderDialog from './AddOrganisationProviderDialog';
import { OrganisationoDetailContext } from './OrganisationDetailView';

const OrganisationMappedProviders = (orgId: any) => {
  const ability = useContext(AbilityContext);
  const { orgProvidersList, getOrgMappedProviders } = useContext(
    OrganisationoDetailContext,
  );

  const [pageSize, setPageSize] = useState<number>(7);
  const [page, setPage] = useState<number>(0);
  const orgProvidersToShow = useMemo(() => {
    return (
      orgProvidersList?.map((obj) => ({
        ...obj,
        services: obj.providerServices.join(', '),
      })) ?? []
    );
  }, [orgProvidersList]);

  const [openProviderOrgDialog, setProviderOrgDialog] = useState<{
    open: boolean;
    edit: boolean;
  }>({ open: false, edit: false });

  const handleClose = () => {
    setProviderOrgDialog({ open: false, edit: false });
  };
  const handleSuccess = () => {
    getOrgMappedProviders();
  };

  const handleDeleteProvider = useCallback(
    async (providerId: number) => {
      // if not having providerId or not having permission to manage providers then error
      if (!providerId || !ability.can(AclActions.Manage, AclModules.PROVIDERS))
        return;

      let response = null;
      try {
        response = await OrganisationServiceV2.deleteOrgMappedProviders(
          orgId.orgId,
          providerId,
        );
        if (response && response.data.success) {
          getOrgMappedProviders();
          toast.success('Provider has been removed');
        }
      } catch (error) {
        toast.error(
          'An error occurred while attempting to delete the organisation mapped providers',
        );
      }
    },
    [ability, getOrgMappedProviders, orgId.orgId],
  );

  return (
    <>
      <SectionHeader
        title={
          <Typography
            color="textPrimary"
            variant="h6"
            fontWeight="bold"
            margin={0}
          >
            Organisation Mapped Providers
          </Typography>
        }
        rightElement={
          <>
            <StyledButton
              variant="ghost"
              startIcon={MaterialIcons.Add}
              disabled={
                !orgId || !ability.can(AclActions.Create, AclModules.PROVIDERS)
              }
              onClick={() =>
                setProviderOrgDialog({
                  open: true,
                  edit: false,
                })
              }
            >
              Add Provider
            </StyledButton>
          </>
        }
      />
      <TableViewComponent
        rows={orgProvidersToShow ?? []}
        pageSizeCustom={pageSize}
        currentPage={page}
        getRowId={(row: any) => row?.providerId ?? row?.providerName}
        onPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
        }}
        onPageChange={(page) => {
          setPage(page);
        }}
        columns={OrgMappedProviderColumns({
          handleDeleteProvider,
        })}
      />
      <AddOrgProviderDialog
        open={openProviderOrgDialog?.open}
        handleClose={handleClose}
        handleSuccess={handleSuccess}
        orgId={orgId.orgId}
      />
    </>
  );
};

export default OrganisationMappedProviders;
