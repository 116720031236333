export const numberWithHyphensRegex = /^[\d -]+$/;

/** Only digits */
export const numberRegex = /^\d+$/;

/** Alpha numeric */
export const caseIdRegex = /^[A-Za-z0-9]+$/;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const noWhiteSpaceRegex = /^[^\s]+(?:$|.*[^\s]+$)/;

export const validDomainRegex = /^(?!www\.)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;

export const domainReplacementRegex = /@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
