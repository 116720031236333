export enum AclActions {
  Manage = 'manage',
  Create = 'create',
  Read = 'read',
}

export enum AclModules {
  ORGANISATION = 'organisation',
  BILLING_PLANS = 'billing_plans',
  CREDIT_REQUESTS = 'credit_requests',
  TRIAGING_REQUESTS = 'triaging_requests',
  CATALOGUE = 'catalogue',
  LEARNING_PATHS = 'learning_paths',
  SESSIONS = 'sessions',
  TRANSLATIONS = 'translations',
  APP_LOCALES = 'app_locales',
  RECOMMENDATIONS = 'recommendations',
  REWARDS = 'rewards',
  PROVIDERS = 'providers',
  CLINICS = 'clinics',
  REPORTING = 'reporting',
  MARKETING_CAMPAIGNS = 'marketing_campaigns',
  ALL = 'all',
  EMAILS = 'emails',
  ONSITE_SESSIONS = 'onsite_sessions',
  CASE_NOTES = 'case_notes',
  STAFF_MANAGEMENT = 'staff_management',
  ACCESS_CONTROL = 'access_control',
  USER_AUTH_CONFIGS = 'user_auth_configs',
  CARE_NAV = 'care_nav',
}

export interface IAclModuleRow {
  moduleName: string;
  subject: AclModules;
}

export const AclModuleRows: IAclModuleRow[] = [
  {
    moduleName: 'Organisation',
    subject: AclModules.ORGANISATION,
  },
  {
    moduleName: 'Billing Plans',
    subject: AclModules.BILLING_PLANS,
  },
  {
    moduleName: 'Credit Request',
    subject: AclModules.CREDIT_REQUESTS,
  },
  {
    moduleName: 'Triaging Request',
    subject: AclModules.TRIAGING_REQUESTS,
  },
  {
    moduleName: 'Providers',
    subject: AclModules.PROVIDERS,
  },
  {
    moduleName: 'Clinics',
    subject: AclModules.CLINICS,
  },
  {
    moduleName: 'Onsite Sessions',
    subject: AclModules.ONSITE_SESSIONS,
  },
  {
    moduleName: 'Case Notes',
    subject: AclModules.CASE_NOTES,
  },
  {
    moduleName: 'Collections/Catalogue',
    subject: AclModules.CATALOGUE,
  },
  {
    moduleName: 'Learning Paths',
    subject: AclModules.LEARNING_PATHS,
  },
  {
    moduleName: 'Sessions',
    subject: AclModules.SESSIONS,
  },
  {
    moduleName: 'Translations',
    subject: AclModules.TRANSLATIONS,
  },
  {
    moduleName: 'App Locales (sync)',
    subject: AclModules.APP_LOCALES,
  },
  {
    moduleName: 'Recommendations',
    subject: AclModules.RECOMMENDATIONS,
  },
  {
    moduleName: 'Rewards',
    subject: AclModules.REWARDS,
  },
  {
    moduleName: 'Marketing Campaigns/Leads',
    subject: AclModules.MARKETING_CAMPAIGNS,
  },
  {
    moduleName: 'Reporting',
    subject: AclModules.REPORTING,
  },
  {
    moduleName: 'Emails',
    subject: AclModules.EMAILS,
  },
  {
    moduleName: 'User Auth Configs',
    subject: AclModules.USER_AUTH_CONFIGS,
  },
  {
    moduleName: 'Care Nav Form (Add User)',
    subject: AclModules.CARE_NAV,
  },
  {
    moduleName: 'Staff Management',
    subject: AclModules.STAFF_MANAGEMENT,
  },
  {
    moduleName: 'Access Control',
    subject: AclModules.ACCESS_CONTROL,
  },
];
