import { Box, Grid, InputLabel, TextField } from '@mui/material';
import { useState } from 'react';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { GenericDialog } from '../../../../../components/genericDialog';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { useOutplacement } from '../hooks/handleOutplacement';
import { emailRegex } from '../../../../../../utilities/FormValidatorRegex';

const MigrateMembersToOutplacementDialog = ({
  open,
  handleClose,
  orgId,
  refreshData,
  memberUserId,
}: {
  open: boolean;
  handleClose: () => void;
  orgId: number;
  refreshData: () => void;
  memberUserId: number;
}) => {
  const [personalEmail, setpersonalEmail] = useState<string>('');
  const [lastWorkingDay, setLastWorkingDay] = useState(moment());
  // const [personalEmail, setPersonalEmail] = React.useState('');
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);

  const isDateValid = (chosenDate: any) => {
    const today = moment();
    return chosenDate.isBefore(today);
  };

  const { mutate } = useOutplacement(() => {
    refreshData();
    handleClose();
  });
  const handleEmailChange = (event: any) => {
    const enteredEmail = event.target.value;

    setIsValidEmail(emailRegex.test(enteredEmail));
    setpersonalEmail(enteredEmail);
  };

  // const moveToOutplacement = (orgId: number, outplacementDetails: any) => {
  //   runOutplacement({ orgId, outplacementDetails });
  // };
  // userEmail: personalEmail,
  // lastDayOfService: moment(lastWorkingDay).format('YYYY-MM-DD'),
  // userId: memberUserId,
  return (
    <GenericDialog
      open={open}
      handleClose={handleClose}
      noFullScreen
      fitToContent
      title="Add personal details of the user"
      extraElements={
        <StyledButton
          sx={{ width: '8rem' }}
          size="small"
          onClick={() =>
            mutate(
              {
                orgId: orgId,
                outplacementDetails: {
                  userEmail: personalEmail,
                  lastDayOfService: moment(lastWorkingDay).format('YYYY-MM-DD'),
                  userId: memberUserId,
                },
              },
              {},
            )
          }
        >
          Submit
        </StyledButton>
      }
    >
      <Grid container spacing={2} paddingX={5}>
        <Grid item xs={6} width={6}>
          <InputLabel>Personal Email</InputLabel>
          <TextField
            fullWidth
            value={personalEmail}
            onChange={handleEmailChange}
            error={!isValidEmail}
            helperText={!isValidEmail ? 'Enter a valid email address' : ''}
            size="small"
          />
        </Grid>

        <Grid item xs={6} width={6}>
          <InputLabel>Last day of service</InputLabel>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              showToolbar={true}
              inputFormat="YYYY/MM/DD"
              mask="____/__/__"
              value={lastWorkingDay}
              onChange={(date: any) =>
                setLastWorkingDay(date ?? moment(date).format('YYYY-MM-DD'))
              }
              OpenPickerButtonProps={{
                'aria-label': 'change valid till date',
              }}
              renderInput={(props) => (
                <TextField
                  id="valid-till-picker"
                  size="small"
                  placeholder="Valid till"
                  error={!isDateValid(lastWorkingDay)}
                  helperText={
                    'Ensure last working date of the employee has already passed'
                  }
                  {...props}
                />
              )}
              shouldDisableDate={(date) => {
                const selectedDate = moment(date);
                const today = moment();
                return selectedDate.isAfter(today);
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

export default MigrateMembersToOutplacementDialog;
