import React, { useContext, useEffect } from 'react';
import { RootViewContext } from '../RootView';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { LoadingView } from '../../components/loadingView';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import FeatureBox from '../../widgets/featureBox';
import MeetingHistoryView from './meetingHistoryView';
import moment from 'moment';
import { useGetMeetingDetails } from './hooks/useGetMeetingDetails';
import {
  MeetingSourceLabels,
  MeetingTypeLabels,
  ProviderStatus,
  STATUS_TEXT,
} from '../../../shared/constants/Provider';
import Config from '../../../config';
import StyledButton from '../../widgets/styledButton/StyledButton';
import { MaterialIcons } from '../../../assets/MaterialIcons';
import { CalendarIcon, XIcon } from '@primer/octicons-react';
import CancelMeetingDialog from '../provider/detail/providerDetailTabs/meetings/CancelMeetingDialog';
import RescheduleMeetingDialog from '../provider/detail/providerDetailTabs/meetings/RescheduleMeetingDialog';
import { Edit } from '@mui/icons-material';
import UpdateMeetingStatusDialog from '../provider/detail/providerDetailTabs/meetings/UpdateMeetingStatusDialog';

const MeetingDetailsView = (paramObject: any) => {
  const { setAppBreadCrumbs } = useContext(RootViewContext);

  const meetingId = paramObject.match.params.meetingId;
  const {
    data,
    isLoading,
    refetch: refetchMeetingDetails,
  } = useGetMeetingDetails(meetingId);
  const [showCancelMeetingDialog, setShowCancelMeetingDialog] =
    React.useState<boolean>(false);

  const [showRescheduleMeetingDialog, setShowRescheduleMeetingDialog] =
    React.useState<boolean>(false);

  const [showMeetingStatusDialog, setShowMeetingStatusDialog] =
    React.useState<boolean>(false);

  const handleUpdateMeeting = () => {
    refetchMeetingDetails();
  };

  useEffect(() => {
    setAppBreadCrumbs([
      {
        label: 'Meeting',
        path: Config.paths.meeting.details,
        type: 'link',
      },
      {
        label: 'Meeting Details',
        type: 'text',
      },
    ]);
  }, []);

  const MeetingInfoItem = ({
    label,
    value,
  }: {
    label: string;
    value: string;
  }) => (
    <Box marginBottom={1} display="flex" flexDirection="column">
      <Typography fontSize={16} fontWeight="bold">
        {value || 'NA'}
      </Typography>
      <Typography fontSize={14} color="#6C737F">
        {label}
      </Typography>
    </Box>
  );

  const meeting = React.useMemo(() => {
    return data?.data?.data[0];
  }, [data]);

  function getMonthAndDay(dateString: string) {
    if (dateString === null || '') {
      return '--';
    }
    const date = moment.utc(dateString);
    const month = date.format('MMMM');
    const year = date.format('YY');
    const day = date.date();
    return `${day}-${month}-${year}`;
  }
  function convertSeconds(seconds: string) {
    const duration = moment.duration(seconds, 'seconds');
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${hours} hour(s), ${minutes} minute(s)`;
  }

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Box>
      <SectionHeader title={'Meeting Details'} />
      <Divider sx={{ my: 3 }} />
      <Box>
        {!!meeting?.id && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: 4 }}
          >
            <Typography>{meetingId}</Typography>
          </Box>
        )}
        {!!meeting && (
          <>
            <Grid container spacing={2} direction="row" alignItems="stretch">
              <Grid item xs={4} width={4}>
                <FeatureBox>
                  <Grid container spacing={2}>
                    <Grid item xs={12} width={12}>
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <MeetingInfoItem
                          label="Scheduled On"
                          value={meeting.scheduledDate}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <MeetingInfoItem
                        label="Scheduled Time"
                        value={moment
                          .unix(meeting?.scheduledStartTime)
                          .format('HH:mm')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box marginBottom={1} display="flex" alignItems="center">
                        <Box flexDirection="column" display="flex">
                          <Typography fontSize={16} fontWeight="bold">
                            {STATUS_TEXT[meeting?.status] || 'N/A'}
                          </Typography>
                          <Typography fontSize={14} color="#6C737F">
                            {'status'}
                          </Typography>
                        </Box>
                        {['0', '2', '3', '6', '7', '8'].includes(
                          meeting?.status,
                        ) && (
                          <IconButton
                            aria-label="edit"
                            disabled={
                              !['0', '2', '3', '6', '7', '8'].includes(
                                meeting?.status,
                              )
                            }
                            onClick={async () => {
                              setShowMeetingStatusDialog(true);
                            }}
                            style={{ marginLeft: 8 }}
                          >
                            {MaterialIcons.Edit}
                          </IconButton>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <MeetingInfoItem
                        label="Participant User Id"
                        value={meeting?.participants[0]?.userId}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MeetingInfoItem
                        label="Participant Friendly Name"
                        value={meeting?.participants[0]?.friendlyName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MeetingInfoItem
                        label="Provider Id"
                        value={meeting.providerId.toString()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MeetingInfoItem
                        label="Provider Name"
                        value={meeting?.provider?.profile?.name.toString()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MeetingInfoItem
                        label="Provider Role"
                        value={ProviderStatus[meeting?.providerRole]?.label}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MeetingInfoItem
                        label="Type"
                        value={MeetingTypeLabels[meeting?.type]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MeetingInfoItem
                        label="Source"
                        value={MeetingSourceLabels[meeting?.source]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MeetingInfoItem
                        label="Duration"
                        value={
                          meeting?.duration
                            ? convertSeconds(meeting?.duration)
                            : 'N/A'
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MeetingInfoItem
                        label="Booked Date"
                        value={moment(meeting?.createdAt)?.format(
                          'YYYY-MM-DD HH:mm:ss',
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Box display="flex" justifyContent={'space-between'}>
                    <Grid
                      item
                      xs={6}
                      display={'flex'}
                      justifyContent={'center'}
                    >
                      <StyledButton
                        variant="ghost"
                        size="small"
                        startIcon={<CalendarIcon size={18} />}
                        onClick={() => setShowRescheduleMeetingDialog(true)}
                        disabled={meeting?.status !== '0'}
                        margin={0}
                      >
                        Reschedule
                      </StyledButton>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display={'flex'}
                      justifyContent={'center'}
                    >
                      <StyledButton
                        size="small"
                        variant="ghost"
                        color="red"
                        startIcon={<XIcon size={18} />}
                        onClick={() => setShowCancelMeetingDialog(true)}
                        disabled={meeting?.status !== '0'}
                        margin={0}
                      >
                        Cancel
                      </StyledButton>
                    </Grid>
                  </Box>
                </FeatureBox>
              </Grid>
              <Grid item xs={8} width={8}>
                <MeetingHistoryView
                  historyData={meeting.history}
                  providerId={meeting?.providerId}
                  userId={String(meeting?.participants?.[0]?.userId)}
                />
              </Grid>
            </Grid>
          </>
        )}
        {meeting?.status === '0' && (
          <>
            <CancelMeetingDialog
              open={showCancelMeetingDialog}
              handleClose={() => setShowCancelMeetingDialog(false)}
              meetingId={meeting?.id}
              userId={String(meeting?.participants?.[0]?.userId)}
            />
            <RescheduleMeetingDialog
              meeting={meeting}
              open={showRescheduleMeetingDialog}
              handleClose={() => setShowRescheduleMeetingDialog(false)}
              timezone={meeting?.provider?.timezone}
            />
          </>
        )}

        <UpdateMeetingStatusDialog
          open={showMeetingStatusDialog}
          handleClose={() => setShowMeetingStatusDialog(false)}
          meetingId={meeting?.id}
          handleSuccess={handleUpdateMeeting}
          currentStatus={meeting?.status}
        />
      </Box>
    </Box>
  );
};

export default MeetingDetailsView;
