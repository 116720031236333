export enum EnvType {
  Localhost = 'localhost',
  Staging = 'staging',
  Production = 'production',
  ProductionJp = 'productionjp',
}
export enum StorageItems {
  USER_INFO = 'userInfo',
  RECENTLY_VISITED_ORGS = 'recentlyVisitedOrgs',
}

export const ImagePrefix = 'https://d3imeoj536zhqj.cloudfront.net/images/';

export const ProductionHostname = 'admin.internal.intellect.co';
export const ProductionJPHostname = 'adminjp.internal.intellect.co';

export const AUDIT_LOG_HEADER_KEY = 'x-intellect-admin-audit-event';
