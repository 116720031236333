import { useState } from 'react';
import toast from 'react-hot-toast';

import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';
import ConfirmDialog from '../../../../../components/confirmDialog/ConfirmDialog';

const DeleteTeamDialog = ({
  open,
  handleClose,
  refreshTeams,
  teamToDelete,
  membersCount,
}: {
  open: boolean;
  handleClose: () => void;
  refreshTeams: () => void;
  teamToDelete: OrganisationTeamItem | null;
  membersCount: number;
}) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteTeam = async () => {
    let response = null;
    try {
      setLoading(true);
      if (teamToDelete?.id) {
        response = await OrganisationServiceV2.deleteTeam(teamToDelete?.id);
        if (response && response.data.success) {
          toast.success('Team deleted successfully!');
          handleClose();
          refreshTeams();
        }
      }
    } catch (err: any) {
      if (err.response?.status === 422) {
        toast.error('Found some active members in the team');
      } else {
        toast.error('An error occurred while attempting to delete a team.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConfirmDialog
      open={open}
      handleClose={handleClose}
      title="Delete Team?"
      onConfirm={handleDeleteTeam}
      disabled={loading}
    >
      Are you sure you want to delete the team <b>{teamToDelete?.name}</b> as it
      has {membersCount} member(s)?
    </ConfirmDialog>
  );
};

export default DeleteTeamDialog;
