import { MaterialIcons } from '../assets/MaterialIcons';
import { AclActions, AclModules } from '../shared/constants/Ability';
import { NavGroup } from '../shared/types/Navbar/Navbar';

const paths = {
  login: '/login',
  home: '/file-upload',
  realHome: '/home',
  unauthorised: '/unauthorised',
  sso: '/sso',
  content: {
    root: '/content',
    home: '/content/home',
    course: '/content/learning-path',
    session: '/content/rescue',
    journals: '/content/journals',
    periodic: '/content/periodic',
    checkins: '/content/checkins',
    toolkit: '/content/toolkit',
    rewards: '/content/reward',
    questionnaire: '/content/questionnaire',
    scoring: '/content/scoring',
  },
  content_v2: {
    root: '/content-v2',
    home: '/content-v2/home',
    catalogue: '/content-v2/catalogue',
    course: '/content-v2/learning-path',
    session: '/content-v2/session',
    recommendation: '/content-v2/recommendations',
    emailTemplate: '/content-v2/email-templates',
    locales: '/content-v2/locales',
    collections: '/content-v2/collection',
    email: '/content-v2/emails',
    template: '/content-v2/emails/templates',
    emailOverride: '/content-v2/emails/override',
    rewards: '/content-v2/rewards',
  },
  organisationV3: '/organisations-v3',
  marketing: {
    home: '/marketing',
    campaigns: '/campaigns',
  },
  provider: {
    home: '/provider',
    list: '/provider/list',
    session: '/provider/session',
    operations: '/provider/operations',
    meetings: '/provider/meetings',
  },
  inPerson: {
    home: '/in-person',
    location: '/in-person/location',
    room: '/in-person/room',
  },
  onsiteSession: {
    home: '/onsite-sessions',
    addNew: '/onsite-sessions/add-new',
    details: '/onsite-sessions/details',
  },
  triaing: {
    domainRequests: '/domain/requests',
    generalRequests: '/general/requests',
  },
  management: {
    home: '/management',
    billing: '/management/billing-v1',
    billingV2: '/management/billing',
    creditRequests: '/management/creditRequests',
    sendReports: '/management/sendReports',
    creditRefunds: '/management/creditRefunds',
    searchUser: '/users',
    searchUserById: '/users/:userId',
  },
  userManagement: {
    home: '/user-role-manegement',
  },
  accessControl: {
    home: '/access-control',
    roles: '/access-control/roles',
    permissions: '/access-control/permissions',
  },
  caseNotes: {
    home: '/case-notes',
  },
  meeting: {
    details: '/meeting/details/:meetingId',
  },
  // settings: '/settings',
  messages: {
    home: '/messages',
  },
  external: {
    plato: '/external/plato',
  },
};

const SidebarItems: NavGroup[] = [
  {
    title: 'Operations',
    path: paths.provider.operations,
    icon: MaterialIcons.Providers,
    children: [
      {
        title: 'Manage Clients',
        path: paths.organisationV3,
        icon: MaterialIcons.Organisation,
        action: AclActions.Read,
        subject: AclModules.ORGANISATION,
      },
      {
        title: '360° View',
        path: paths.provider.operations,
        icon: MaterialIcons.Leads,
        action: AclActions.Read,
        subject: AclModules.PROVIDERS,
      },

      {
        title: 'Provider List',
        path: paths.provider.list,
        icon: MaterialIcons.Providers,
        action: AclActions.Read,
        subject: AclModules.PROVIDERS,
      },
      {
        title: 'Provider Calendar',
        path: paths.provider.meetings,
        icon: MaterialIcons.CalendarIcon,
        action: AclActions.Read,
        subject: AclModules.PROVIDERS,
      },
      {
        title: 'Reporting',
        path: paths.management.sendReports,
        icon: MaterialIcons.SendReports,
        action: AclActions.Read,
        subject: AclModules.REPORTING,
      },
      {
        title: 'Manage Clinics',
        path: paths.inPerson.home,
        icon: MaterialIcons.InPerson,
        action: AclActions.Read,
        subject: AclModules.CLINICS,
      },
      {
        title: 'Case Notes',
        path: paths.caseNotes.home,
        icon: MaterialIcons.NoteIcon,
        action: AclActions.Manage,
        subject: AclModules.CASE_NOTES,
      },
    ],
  },
  {
    title: 'Content(CMS)',
    path: paths.content_v2.home,
    icon: MaterialIcons.Collections,
    // alwaysShow: true,
    children: [
      {
        title: 'Collections',
        path: paths.content_v2.collections,
        icon: MaterialIcons.Collections,
        action: AclActions.Read,
        subject: AclModules.CATALOGUE,
        hidden: true,
      },
      {
        title: 'Catalogue',
        path: paths.content_v2.catalogue,
        icon: MaterialIcons.Catalogue,
        action: AclActions.Read,
        subject: AclModules.CATALOGUE,
        hidden: true,
      },
      {
        title: 'Learning Paths',
        path: paths.content_v2.course,
        icon: MaterialIcons.LearningPath,
        action: AclActions.Read,
        subject: AclModules.LEARNING_PATHS,
        hidden: true,
      },
      {
        title: 'Sessions',
        path: paths.content_v2.session,
        icon: MaterialIcons.Sessions,
        action: AclActions.Read,
        subject: AclModules.SESSIONS,
        hidden: true,
      },
      {
        title: 'Locales sync',
        path: paths.content_v2.locales,
        icon: MaterialIcons.Translate,
        action: AclActions.Read,
        subject: AclModules.APP_LOCALES,
        hidden: true,
      },
      {
        title: 'Recommendations',
        path: paths.content_v2.recommendation,
        icon: MaterialIcons.Recommendations,
        action: AclActions.Read,
        subject: AclModules.RECOMMENDATIONS,
        hidden: true,
      },
    ],
  },
  {
    title: 'Support',
    path: paths.management.searchUser,
    icon: MaterialIcons.Collections,
    children: [
      {
        title: 'Manage users',
        path: paths.management.searchUser,
        icon: MaterialIcons.Search,
        action: AclActions.Read,
        subject: AclModules.ORGANISATION,
      },
      {
        title: 'Credit Requests',
        path: paths.management.creditRequests,
        icon: MaterialIcons.CreditRequests,
        action: AclActions.Read,
        subject: AclModules.CREDIT_REQUESTS,
      },
      {
        title: 'Domain Requests',
        path: paths.triaing.domainRequests,
        icon: MaterialIcons.DomainRequests,
        action: AclActions.Read,
        subject: AclModules.TRIAGING_REQUESTS,
      },
      {
        title: 'General Requests',
        path: paths.triaing.generalRequests,
        icon: MaterialIcons.DomainRequests,
        action: AclActions.Read,
        subject: AclModules.TRIAGING_REQUESTS,
      },
    ],
  },
  {
    title: 'Misc',
    path: paths.home,
    icon: MaterialIcons.Reports,
    children: [
      {
        title: 'Onsite Sessions',
        path: paths.onsiteSession.home,
        icon: MaterialIcons.B2B,
        action: AclActions.Read,
        subject: AclModules.ONSITE_SESSIONS,
      },
      {
        title: 'Upload File',
        path: paths.home,
        icon: MaterialIcons.Reports,
        alwaysShow: true,
      },
      {
        title: 'Marketing Campaigns',
        path: paths.marketing.campaigns,
        icon: MaterialIcons.Campaigns,
        action: AclActions.Read,
        subject: AclModules.MARKETING_CAMPAIGNS,
      },
      {
        title: 'Marketing Leads',
        path: paths.marketing.home,
        icon: MaterialIcons.Leads,
        action: AclActions.Read,
        subject: AclModules.MARKETING_CAMPAIGNS,
      },
      {
        title: 'Manage Email templates',
        path: paths.content_v2.email,
        icon: MaterialIcons.Email,
        action: AclActions.Read,
        subject: AclModules.EMAILS,
      },
      {
        title: 'Automated Messages',
        path: `${paths.messages.home}/triggers`,
        icon: MaterialIcons.Chat,
        action: AclActions.Read,
        subject: AclModules.EMAILS,
      },
      {
        title: 'Staff Management',
        path: paths.userManagement.home,
        icon: MaterialIcons.PersonIcon,
        action: AclActions.Read,
        subject: AclModules.STAFF_MANAGEMENT,
      },
      {
        title: 'Roles & Permissions',
        path: paths.accessControl.home,
        icon: MaterialIcons.KeyIcon,
        action: AclActions.Read,
        subject: AclModules.ACCESS_CONTROL,
      },
    ],
  },
];

const Config = {
  paths,
  drawerWidth: 236,
  sidebar: SidebarItems,
};

export default Config;
